<template>
  <CurrentEventDataProvider v-slot="{ data, logoUrl }">
    <div class="flex flex-wrap overflow-hidden w-full h-full md:space-y-2">
      <!-- Navbar -->
      <div
        class="navbar top-0 z-30 flex justify-between items-center text-center sticky font-bold bg-dark px-4 w-full"
      >
        <div class="flex items-center h-16">
          <img
            :src="'https://jolly-coast-0ed26f703-development.westeurope.3.azurestaticapps.net/ec7c06a311e758b602863004841e8d19.svg'"
            :alt="logoUrl && 'Event\'s logo'"
            class="object-scale-down h-6 md:h-12 w-auto"
          />
        </div>
      </div>
      <!--  -->
      <div class="flex flex-row w-full h-full px-2 md:px-4">
        <!-- Sidebar -->
        <div
          v-if="showSlider && showPartial"
          class="w-full md:w-1/4 flex flex-col"
        >
          <div class="sidebar-container shadow-lg p-4 space-y-4 md:space-y-6">
            <!-- Event detail -->
            <div class="flex justify-between items-center w-full">
              <div class="shadow-md p-1 rounded-full">
                <img
                  :src="logoUrl"
                  :alt="logoUrl && 'Event\'s logo'"
                  class="object-scale-down h-8 w-8"
                />
              </div>
              <h2 class="font-bold text-md">{{ data.eventName }}</h2>
              <i class="fas fa-chevron-down ml-2" aria-hidden="true" />
            </div>
            <!-- Filters refresh-->
            <div
              class="flex flex-col md:flex-row justify-between md:items-center w-full"
            >
              <!-- Filters hidden for small screen-->
              <h2
                class="hidden md:flex font-bold text-base pt-2 md:pt-0 items-center"
              >
                Filters
              </h2>
              <div class="flex w-full justify-between md:justify-end">
                <TappinButton
                  :disabled="isLoading"
                  :icon="isLoading ? 'circle-notch' : 'repeat'"
                  @click="refreshItems"
                  class="button button-secondary text-center items-center"
                  :aria-label="isLoading ? 'loading' : 'Refresh'"
                  :custom_caption="
                    isLoading ? '' : $t('Views.PollResults.refresh')
                  "
                />
                <TappinButton
                  v-if="showSlider"
                  :icon="'close'"
                  @click="toggleSlider"
                  class="inline md:hidden whitespace-nowrap button button-secondary"
                  :aria-label="'Close filter'"
                  :custom_caption="'close filter'"
                />
              </div>
              <!-- Filters hidden for large screen-->
              <h2 class="md:hidden font-bold text-base pt-2 md:pt-0">
                Filters
              </h2>
            </div>
            <!-- Date picker -->
            <div class="flex flex-col gap-2">
              <h2 class="font-bold text-base">Date</h2>
              <Filters
                v-if="poll.pollType === 0"
                showDates
                @date-filter="handleDates"
                @checkbox-filter="handleCheckBoxFilters"
              />
            </div>
            <!-- Group -->
            <div class="flex flex-col justify-between gap-2">
              <div class="flex justify-between border-b border-grey p-2">
                <h2 class="font-bold text-base">
                  {{ $t('Components.Filters.groups') }}
                </h2>
                <i class="fas fa-chevron-down ml-2" aria-hidden="true" />
              </div>
              <Filters
                showCheckbox
                @checkbox-filter="handleCheckBoxFilters"
                :groups="groups"
                :tableCheckBoxName="$t('Components.Filters.groups')"
                showGroupModal
              />
            </div>
          </div>
        </div>
        <!-- Content -->
        <div
          :class="
            showSlider
              ? showPartial
                ? 'w-1/6 md:w-3/4 flex flex-col space-y-4 md:ml-4'
                : 'w-full'
              : 'w-full md:w-3/4 flex flex-col space-y-4  md:ml-4'
          "
        >
          <!--loader-->
          <div v-if="isLoading" slot="footer" class="loading-spinner">
            <Spinner />
          </div>
          <div
            class="poll-results shadow-lg py-2 md:p-2 space-y-0"
            style="
              color: var(--login-color-text);
              background-color: var(--login-background-color);
            "
          >
            <div v-if="!isPollLoaded">
              {{ $t('Views.PollResults.loading') }}
            </div>
            <div
              v-else-if="isPollLoaded && !isPollError"
              class="flex flex-col h-full space-y-0 md:space-y-2 px-2 pb-4"
            >
              <!--header and export-->
              <div v-if="showPartial" class="flex justify-end w-full space-x-2">
                <TappinButton
                  class="button button-primary"
                  :custom_caption="'Export'"
                />
                <div class="button flex lg:hidden" @click="toggleSlider">
                  <TappinButton
                    v-if="!showSlider"
                    icon="filter"
                    class="button button-primary"
                    :custom_caption="'Filters'"
                  />
                </div>
                <div
                  class="button button-primary hidden md:block toggle-fullscreen shrink-0 self-start"
                  @click="requestFullScreen"
                >
                  Show Full screen
                </div>
              </div>
              <!--poll content-->
              <!--poll content-->
              <div
                id="fullscreen"
                :class="[
                  'custom-card-parent',
                  'flex',
                  'flex-col',
                  'h-full',
                  'bg-white',
                  'text-dark',
                  'justify-between',
                  'py-2'
                ]"
              >
                <div class="card rounded-md bg-white">
                  <MultipleChoicePoll
                    v-if="poll.pollType === 0 || poll.pollType === 10"
                    :poll="poll"
                  />
                  <OpenTextPoll v-else :poll="poll" />
                </div>
              </div>
            </div>

            <Card v-else class="p-10 lg:w-2/5">
              <h2 class="font-bold text-md text-center">
                Could not find poll to show
              </h2>
            </Card>
          </div>
        </div>
      </div>
    </div>
  </CurrentEventDataProvider>
</template>

<script>
import ApiClient from '@/client';
import { mapActions, mapGetters } from 'vuex';
import Spinner from '@/components/shared/Spinner';
import Filters from '@/components/shared/Filters.vue';
import TappinButton from '@/components/buttons/TappinButton.vue';
import Card from '@/components/shared/Card';
import MultipleChoicePoll from '@/components/polls/MultipleChoicePoll';
import OpenTextPoll from '@/components/polls/OpenTextPoll';
import { getPusherInstance } from '@/utils';

export default {
  props: {
    pollAccessKey: String,
    showPartial: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Spinner,
    Filters,
    Card,
    TappinButton,
    MultipleChoicePoll,
    OpenTextPoll
  },
  data() {
    return {
      isLoading: false,
      showSlider: false,
      isPollLoaded: false,
      isPollError: false,
      poll: {
        allVoteCount: 1,
        eventName: '',
        title: '',
        votes: [],
        participantsCount: undefined,
        delegationIds: [],
        allVoteCountBase: 1
      },
      eventId: window.location.host.split('.')[0],
      fromDate: '',
      toDate: '',
      groupsFilter: [],
      debouncedSearchTimeout: null,
      loadingFilter: false
    };
  },
  created() {
    this.fetchItems();
    this.fetchGroups();
    getPusherInstance({ isReady: true }).bind(
      `poll-change-${this.pollAccessKey}`,
      () => {
        this.fetchItems();
      }
    );

    this.checkScreenSize();
    // Add an event listener to react to screen resize
    window.addEventListener('resize', this.checkScreenSize);
  },
  computed: {
    ...mapGetters('Groups', { groups: 'getItems' }),
    ...mapGetters('CurrentEvent', [{ eventName: 'getTitle' }]),
    ...mapGetters('CurrentEvent', [
      'getLocale',
      'getEvent',
      'isWebappEnabled',
      'isLobbyEnabled',
      'features'
    ])
  },

  methods: {
    ...mapActions('Groups', { fetchGroups: 'fetchItems' }),
    toggleSlider() {
      this.showSlider = !this.showSlider;
    },
    fetchItems() {
      ApiClient.getPollStream(
        this.eventId,
        this.pollAccessKey,
        this.fromDate,
        this.toDate,
        this.groupsFilter
      )
        .then((response) => {
          this.poll = response.data;
          this.isPollLoaded = true;
          this.isPollError = false;
          this.poll.votes.sort((a, b) => (a.count < b.count ? 1 : -1));
          this.poll.allVoteCount = response.data.votes.reduce(function (s, a) {
            return s + a.count;
          }, 0);
          this.poll.allVoteCountBase = response.data.baseVotes.reduce(function (
            s,
            a
          ) {
            return s + a.count;
          },
          0);
          this.loadingFilter = false;
        })
        .catch(() => {
          this.isPollLoaded = true;
          this.isPollError = true;
          this.loadingFilter = true;
        });
    },
    handleDates(e) {
      this.fromDate = e.from;
      this.toDate = e.to;

      if (this.toDate || (this.fromDate && this.toDate)) {
        this.fetchItems({
          eventId: this.eventId,
          pollAccessKey: this.pollAccessKey,
          fromDate: this.fromDate,
          toDate: this.toDate,
          groups: this.groupsFilter
        });
      }
    },
    handleCheckBoxFilters(e) {
      this.loadingFilter = true;
      this.groupsFilter = e;
      this.fetchItems({
        eventId: this.eventId,
        pollAccessKey: this.pollAccessKey,
        fromDate: this.fromDate,
        toDate: this.toDate,
        groups: this.groupsFilter
      });
    },
    requestFullScreen() {
      const elem = document.getElementById('fullscreen');
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    },
    checkScreenSize() {
      // Use window.innerWidth to get the current viewport width
      // You can adjust the breakpoint value according to your requirements
      if (window.innerWidth >= 1024) {
        this.showSlider = true;
      } else {
        this.showSlider = false;
      }
    },
    refreshItems() {
      this.fetchItems();
    },

    destroyed() {
      // Remove the event listener when the component is destroyed
      window.removeEventListener('resize', this.checkScreenSize);
    }
  }
};
</script>

<style scoped lang="scss">
:root {
  --sidebar-height: 100%;
}
@media screen and (max-width: 1023px) {
  .slider {
    display: none;
    position: fixed;
    top: -100%; /* Initially hide the slider off-screen */
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    transition: top 0.5s; /* Add a smooth transition for opening and closing */
    z-index: 999; /* Ensure the slider is above other content */
  }

  .slider.open {
    top: 0; /* Move the slider to the top of the screen when open */
  }
}
.slider {
  display: flex;
}
.custom-card-parent .card {
  box-shadow: none;
}
.poll-results {
  height: calc(100vh - 5em);
  .card {
    .toggle-type {
      color: var(--color-secondary-bg);
      opacity: 0.75;
    }
  }
}
.sidebar-container {
  overflow-y: auto;
  height: calc(100vh - 5em);
}
</style>
