<template>
  <CurrentEventDataProvider v-slot="{ data, logoUrl }">
    <div class="flex flex-wrap overflow-hidden w-full md:space-y-2">
      <!-- Navbar -->
      <div
        v-if="showPartial"
        class="navbar top-0 z-30 flex justify-between items-center text-center sticky font-bold bg-dark px-4 w-full"
      >
        <div class="flex items-center h-16">
          <img
            :src="'https://jolly-coast-0ed26f703-development.westeurope.3.azurestaticapps.net/ec7c06a311e758b602863004841e8d19.svg'"
            :alt="logoUrl && 'Event\'s logo'"
            class="object-scale-down h-6 md:h-12 w-auto"
          />
        </div>
      </div>
      <!--  -->
      <div class="flex flex-row w-full h-full px-2 md:px-4">
        <!-- Sidebar -->
        <div
          v-if="showSlider && showPartial"
          class="w-full md:w-1/4 flex flex-col"
        >
          <div class="sidebar-container shadow-lg p-4 space-y-4 md:space-y-6">
            <!-- Event detail -->
            <div class="flex justify-between items-center w-full">
              <div class="shadow-md p-1 rounded-full">
                <img
                  :src="logoUrl"
                  :alt="logoUrl && 'Event\'s logo'"
                  class="object-scale-down h-8 w-8"
                />
              </div>
              <h2 class="font-bold text-md">{{ data.eventName }}</h2>
              <i class="fas fa-chevron-down ml-2" aria-hidden="true" />
            </div>
            <!-- Filters refresh-->
            <div
              class="flex flex-col md:flex-row justify-between md:items-center w-full"
            >
              <!-- Filters hidden for small screen-->
              <h2
                class="hidden md:flex font-bold text-base pt-2 md:pt-0 items-center"
              >
                Filters
              </h2>
              <div class="flex w-full justify-between md:justify-end">
                <TappinButton
                  :disabled="isLoading"
                  :icon="isLoading ? 'circle-notch' : 'repeat'"
                  @click="refreshItems"
                  class="button button-secondary text-center items-center"
                  :aria-label="isLoading ? 'loading' : 'Refresh'"
                  :custom_caption="
                    isLoading ? '' : $t('Views.PollResults.refresh')
                  "
                />
                <TappinButton
                  v-if="showSlider"
                  :icon="'close'"
                  @click="toggleSlider"
                  class="inline md:hidden whitespace-nowrap button button-secondary"
                  :aria-label="'Close filter'"
                  :custom_caption="'close filter'"
                />
              </div>
              <!-- Filters hidden for large screen-->
              <h2 class="md:hidden font-bold text-base pt-2 md:pt-0">
                Filters
              </h2>
            </div>
            <!-- Date picker -->
            <div class="flex flex-col gap-2">
              <h2 class="font-bold text-base">Date</h2>
              <Filters
                v-if="poll.pollType === 0 || isAllAnswersQuiz"
                showDates
                @date-filter="handleDates"
                @checkbox-filter="handleCheckBoxFilters"
              />
            </div>
            <!-- Group -->
            <div class="flex flex-col justify-between gap-2">
              <div class="flex justify-between border-b border-grey p-2">
                <h2 class="font-bold text-base">
                  {{ $t('Components.Filters.groups') }}
                </h2>
                <i class="fas fa-chevron-down ml-2" aria-hidden="true" />
              </div>
              <Filters
                showCheckbox
                @checkbox-filter="handleCheckBoxFilters"
                :groups="groups"
                :tableCheckBoxName="$t('Components.Filters.groups')"
                showGroupModal
              />
            </div>
          </div>
        </div>
        <!-- Content -->
        <div
          :class="
            showSlider
              ? showPartial
                ? 'w-1/6 md:w-3/4 flex flex-col space-y-4 md:ml-4'
                : 'w-full'
              : 'w-full md:w-3/4 flex flex-col space-y-4  md:ml-4'
          "
        >
          <!--loader-->
          <div
            v-if="isLoading && !pollAccessKey"
            slot="footer"
            class="loading-spinner"
          >
            <Spinner />
          </div>
          <div
            class="poll-results shadow-lg py-2 md:p-2 space-y-0"
            style="
              color: var(--login-color-text);
              background-color: var(--login-background-color);
            "
          >
            <div v-if="!isPollLoaded">
              {{ $t('Views.PollResults.loading') }}
            </div>
            <div
              class="flex flex-col h-full space-y-0 md:space-y-2 px-2 pb-4"
              v-else-if="isPollLoaded && !isPollError"
            >
              <!--header and export-->
              <div v-if="showPartial" class="flex justify-end w-full space-x-2">
                <TappinButton
                  :icon="loadingExport ? 'circle-notch' : ''"
                  class="button button-primary"
                  :disabled="loadingExport"
                  @click="handleExport"
                  :custom_caption="loadingExport ? '' : 'Export'"
                />
                <div class="button flex lg:hidden" @click="toggleSlider">
                  <TappinButton
                    v-if="!showSlider"
                    icon="filter"
                    class="button button-primary"
                    :custom_caption="'Filters'"
                  />
                </div>
                <div
                  class="button button-primary hidden md:block toggle-fullscreen shrink-0 self-start"
                  @click="requestFullScreen"
                >
                  Show Full screen
                </div>
              </div>
              <!--poll content-->
              <div
                id="fullscreen"
                :class="[
                  'custom-card-parent',
                  'flex',
                  'flex-col',
                  'h-full',
                  'bg-white',
                  'text-dark',
                  'justify-between',
                  'py-2'
                ]"
              >
                <div class="card rounded-md bg-white">
                  <div
                    v-if="poll.pollType == 0 || isAllAnswersQuiz"
                    class="flex justify-end"
                  >
                    <PollActions
                      :hasPrevious="hasPrevious"
                      :hasNext="hasNext"
                      :previous="previousPoll"
                      :next="nextPoll"
                    />
                  </div>
                  <OpenTextList
                    v-if="poll.pollType === 1 && isAllAnswersQuiz"
                    :poll="poll"
                    :quizTitle="title"
                  />
                  <OpenTextPoll
                    v-if="poll.pollType === 1 && !isAllAnswersQuiz"
                    :poll="poll"
                  />
                  <MultipleChoicePoll
                    v-if="poll.pollType === 0 || poll.pollType === 10"
                    :poll="poll"
                    :quizTitle="title"
                    :averages="averages"
                    :showAverages="showAverages"
                  />
                </div>
              </div>
            </div>
            <Card v-else class="p-10 lg:w-2/5">
              <h2 class="font-bold text-md text-center">
                {{ $t('Views.PollResults.pollNotFound') }}
              </h2>
            </Card>
          </div>
        </div>
      </div>
    </div></CurrentEventDataProvider
  >
</template>

<script>
import ApiClient from '@/client';
import { mapActions, mapGetters } from 'vuex';
import { getPusherInstance } from '@/utils';
import Card from '@/components/shared/Card';
import Filters from '@/components/shared/Filters.vue';
import Spinner from '@/components/shared/Spinner';
import TappinButton from '@/components/buttons/TappinButton.vue';
import MultipleChoicePoll from '@/components/polls/MultipleChoicePoll';
import OpenTextPoll from '@/components/polls/OpenTextPoll';
import PollActions from '@/components/shared/PollActions.vue';
import { pollType, resultsPageType } from '@/constants/poll-type-constants';
import OpenTextList from '@/components/polls/OpenTextList.vue';

export default {
  components: {
    Spinner,
    Card,
    Filters,
    TappinButton,
    MultipleChoicePoll,
    PollActions,
    OpenTextList,
    OpenTextPoll
  },
  props: {
    pollAccessKey: String,
    resultsPageType: String,
    showPartial: {
      type: Boolean,
      default: true
    },
    resultsFromDate: String,
    resultsToDate: String,
    resultsGroups: Array
  },
  data() {
    return {
      isLoading: false,
      eventId: window.location.host.split('.')[0],
      pageType: window.location.pathname.split('/')[1],
      checkBoxFilter: [],
      groupsFilter: this.resultsGroups || [],
      fromDate: this.resultsFromDate || '',
      toDate: this.resultsToDate || '',
      loadingFilter: false,
      debouncedSearchTimeout: null,
      showSlider: false,
      multipleAnswerPolls: [],
      openTextPolls: [],
      currentPoll: 0,
      title: '',
      poll: {
        allVoteCount: 1,
        title: '',
        votes: [],
        participantsCount: undefined,
        allVoteCountBase: 1,
        delegationIds: []
      },
      polls: [],
      isPollLoaded: false,
      isPollError: false,
      averages: {},
      showAverages: false,
      loadingExport: false
    };
  },
  created() {
    this.fetchItems();
    this.fetchGroups();
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);

    getPusherInstance({ isReady: true }).bind(
      `quiz-change-${this.pollAccessKey}`,
      () => {
        this.fetchItems();
      }
    );
  },
  watch: {
    resultsFromDate: {
      handler(val) {
        this.fromDate = val;
      }
    },
    resultsToDate: {
      handler(val) {
        this.toDate = val;
        this.fetchItems();
      }
    },
    resultsGroups: {
      handler(val) {
        this.groupsFilter = val;
        this.fetchItems();
      }
    }
  },
  computed: {
    ...mapGetters('Groups', { groups: 'getItems' }),
    ...mapGetters('CurrentEvent', [{ eventName: 'getTitle' }]),
    ...mapGetters('CurrentEvent', [
      'getLocale',
      'getEvent',
      'isWebappEnabled',
      'isLobbyEnabled',
      'features'
    ]),
    hasNext() {
      return this.polls.length !== this.currentPoll + 1;
    },
    hasPrevious() {
      return this.currentPoll !== 0;
    },
    isAllAnswersQuiz() {
      return (
        this.pageType === 'quiz' ||
        this.pageType === 'treasure-hunt' ||
        this.resultsPageType === 'quiz'
      );
    },
    isOpenTextQuiz() {
      return (
        this.pageType === 'quiz-open-text' ||
        this.pageType === 'treasure-hunt-open-text'
      );
    }
  },
  methods: {
    ...mapActions('Polls', ['filterPollResults']),
    ...mapActions('Groups', { fetchGroups: 'fetchItems' }),
    fetchItems() {
      this.loadPolls();
    },
    loadPolls() {
      this.isLoading = true;
      ApiClient.getQuizStream(
        this.pollAccessKey,
        this.fromDate,
        this.toDate,
        this.groupsFilter
      )
        .then((response) => {
          this.showAverages = response.data?.showAverage;
          this.averages = {
            quizAverage: response.data?.average,
            totalAverage: response.data?.totalAverage
          };
          this.data = response.data;
          this.handleData(response.data);
        })
        .catch((e) => console.error(`Critical failure: ${e.message}`));
    },
    toggleSlider() {
      this.showSlider = !this.showSlider;
    },
    handleData(data) {
      this.title = data.title;
      this.polls = data.items;

      if (this.isAllAnswersQuiz) {
        this.isLoading = false;
        this.poll = data.items[this.currentPoll];
      }

      if (this.isOpenTextQuiz) {
        this.isLoading = false;
        this.openTextPolls = data.items.filter(
          (x) => x.pollType === pollType.OPEN_TEXT
        );
        const selectedOpenText = this.openTextPolls.find((poll) =>
          poll.votes.some((vote) => vote.isSelected === true)
        );
        if (selectedOpenText) {
          this.poll = selectedOpenText;
        } else {
          this.poll = this.openTextPolls[0];
        }
      }
      if (this.poll) {
        this.isLoading = false;
        this.poll.votes.sort((a, b) => b.count - a.count);
        this.poll.allVoteCount = this.poll.votes.reduce(
          (s, a) => s + a.count,
          0
        );
        this.poll.allVoteCountBase = this.poll.baseVotes.reduce(function (
          s,
          a
        ) {
          return s + a.count;
        },
        0);
        this.poll.participantsCount = data?.participantsCount;
      }
      this.isPollError = !this.poll;
      this.isPollLoaded = true;
      this.loadingFilter = false;
    },
    handleDates(e) {
      this.fromDate = e.from;
      this.toDate = e.to;

      if (this.toDate || (this.fromDate && this.toDate)) {
        this.fetchItems();
      }
    },
    handleResetFilter() {
      this.checkBoxFilter = [];
      this.$emit('checkbox-filter', this.checkBoxFilter);
    },
    handleSaveFilter() {
      this.$emit('checkbox-filter', this.checkBoxFilter);
    },
    handleCheckBox(e) {
      const value = e.target.value.trim();
      const isChecked = this.checkBoxFilter.find((v) => v === value);
      if (!isChecked) {
        this.checkBoxFilter.push(value);
      } else {
        this.checkBoxFilter = this.checkBoxFilter.filter(
          (v) => v.trim() !== value
        );
      }
    },
    handleCheckBoxFilters(e) {
      this.groupsFilter = e;
      this.loadingFilter = true;
      this.fetchItems();
    },
    requestFullScreen() {
      const elem = document.getElementById('fullscreen');
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    },
    changePoll(direction) {
      this.currentPoll += direction;
      this.poll = this.polls[this.currentPoll];
      /*if (this.isMultipleAnswerQuiz) {
        this.poll = this.multipleAnswerPolls[this.currentPoll];
      }
      if (this.isOpenTextQuiz) {
        this.poll = this.openTextPolls[this.currentPoll];
      }
    */
      this.poll.allVoteCount = this.poll.votes.reduce(function (s, a) {
        return s + a.count;
      }, 0);
    },
    nextPoll() {
      this.changePoll(1);
    },
    previousPoll() {
      this.changePoll(-1);
    },
    checkScreenSize() {
      // Use window.innerWidth to get the current viewport width
      // You can adjust the breakpoint value according to your requirements
      if (window.innerWidth >= 1024) {
        this.showSlider = true;
      } else {
        this.showSlider = false;
      }
    },
    refreshItems() {
      this.fetchItems();
    },
    async handleExport() {
      this.loadingExport = true;
      await ApiClient.exportPollResults(
        this.pollAccessKey,
        this.poll.pollType === 1
          ? resultsPageType.OPEN_TEXT
          : resultsPageType.MULTIPLE_ANSWERS,
        this.fromDate,
        this.toDate,
        this.groupsFilter,
        false
      );
      this.loadingExport = false;
    }
  },
  destroyed() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('resize', this.checkScreenSize);
  }
};
</script>

<style scoped lang="scss">
:root {
  --sidebar-height: 100%;
}
@media screen and (max-width: 1023px) {
  .slider {
    display: none;
    position: fixed;
    top: -100%; /* Initially hide the slider off-screen */
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    transition: top 0.5s; /* Add a smooth transition for opening and closing */
    z-index: 999; /* Ensure the slider is above other content */
  }

  .slider.open {
    top: 0; /* Move the slider to the top of the screen when open */
  }
}
.slider {
  display: flex;
}
.custom-card-parent .card {
  box-shadow: none;
}
.poll-results {
  height: calc(100vh - 10em);
  .card {
    .toggle-type {
      color: var(--color-secondary-bg);
      opacity: 0.75;
    }
  }
}
.sidebar-container {
  overflow-y: auto;
  height: calc(100vh - 5em);
}

.card {
  transition: all 200ms;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
</style>
